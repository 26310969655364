import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  EnvironmentInjector,
  Injectable,
  TemplateRef,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { DialogComponent } from './dialog.component';
import { DialogOptions } from './dialog-options';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  newModalComponent!: ComponentRef<DialogComponent>;
  options!: DialogOptions | undefined;

  constructor(
    private appRef: ApplicationRef,
    private injector: EnvironmentInjector
  ) {}

  open(
    vcrOrComponent: ViewContainerRef,
    content: TemplateRef<Element>,
    options?: DialogOptions
  ): void;

  open<C>(vcrOrComponent: Type<C>, options?: DialogOptions): ComponentRef<unknown>;

  open<C>(
    vcrOrComponent: ViewContainerRef | Type<C>,
    param2?: TemplateRef<Element> | DialogOptions,
    options?: DialogOptions
  ) {
    if (vcrOrComponent instanceof ViewContainerRef) {
      return this.openWithTemplate(vcrOrComponent, param2 as TemplateRef<Element>);
      this.options = options;
    } else {
      return this.openWithComponent(vcrOrComponent);
      this.options = param2 as DialogOptions | undefined;
    }
  }

  private openWithTemplate(vcr: ViewContainerRef, content: TemplateRef<Element>) {
    vcr.clear();

    const innerContent = vcr.createEmbeddedView(content);

    this.newModalComponent = vcr.createComponent(DialogComponent, {
      environmentInjector: this.injector,
      projectableNodes: [innerContent.rootNodes],
    });

    return this.newModalComponent;
  }

  private openWithComponent(component: Type<unknown>) {
    const newComponent = createComponent(component, {
      environmentInjector: this.injector,
    });

    this.newModalComponent = createComponent(DialogComponent, {
      environmentInjector: this.injector,
      projectableNodes: [[newComponent.location.nativeElement]],
    });

    document.body.appendChild(this.newModalComponent.location.nativeElement);

    // Attach views to the changeDetection cycle
    this.appRef.attachView(newComponent.hostView);
    this.appRef.attachView(this.newModalComponent.hostView);

    return newComponent;
  }

  close() {
    this.newModalComponent?.instance?.close();
    this.newModalComponent = undefined;
  }

  showMessageDialog(
    title: string,
    message: string,
    autocloseTimeout?: number,
    enableCloseButton?: boolean,
    closeButtonLabel?: string
  ) {
    this.close();

    const messageDialog = this.open(MessageDialogComponent, {});
    const component = messageDialog.instance as MessageDialogComponent;
    component.title = title;
    component.message = message;
    component.enableCloseButton = enableCloseButton;
    component.closeButtonLabel = closeButtonLabel;
    if (autocloseTimeout)
      setTimeout(() => {
        this.close();
      }, autocloseTimeout);
  }

  showErrorDialog(e: any) {
    console.error(e);
    this.showMessageDialog(
      'Ein Fehler ist aufgetreten',
      ((e?.response?.body?.message ||
        this.assertHttpError(e) ||
        e?.error?.message ||
        JSON.stringify(e?.response?.error) ||
        e?.message ||
        e?.toString()) as string) || 'Unbekannter Fehler',
      1000 * 5,
      true
    );
  }

  private assertHttpError(e: any) {
    if (e?.status === 0) return 'HTTP_ERROR';
    return undefined;
  }

  isOpen(): boolean {
    return !!this.newModalComponent;
  }
}
